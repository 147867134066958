import { Link } from "react-router-dom";
import { useFETCH } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { Add, CardBox, Loading, Title } from "../../components";
import { Pagination } from "../../components";

const WarningUsers = () => {
  const { data, isLoading, deleteItem } = useFETCH(`admin/warning-users`);
  console.log(data);
  return (
    <>
      <Title title="قسم التفتيش" />
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          {isLoading ? (
            <Loading />
          ) : (
            data?.data.data.data.map((e) => (
              <Col md={4} xs={6}>
                <CardBox
                  deleteClick={() => deleteItem(e)}
                  edit={`/warning-users/${e.id}`}
                >
                  <div className="flex gap-2 mb-2 justify-start">
                    <div className="font-semibold text-boldGreen">الاسم :</div>
                    <div>{e.name}</div>
                  </div>
                  <div className="flex gap-2 mb-2 justify-start">
                    <div className="font-semibold text-boldGreen">الرقم :</div>
                    <div>{e.number}</div>
                  </div>
                </CardBox>
              </Col>
            ))
          )}
        </Row>
      </Pagination>
      <Link to="/warning-users/add">
        <Add />
      </Link>
    </>
  );
};

export default WarningUsers;
