import { Loading, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Income = () => {
  const { filter, setFilter } = useFilter();
  const { data, isLoading } = useFETCH(
    `admin/orders?income=1${
      filter.get("sort_by") ? "&sort_by=" + filter.get("sort_by") : ""
    }${
      filter.get("sort_value") ? "&sort_value=" + filter.get("sort_value") : ""
    }`
  );

  return (
    <div>
      <Title title="مدخلات" />
      {isLoading ? <Loading /> : ""}
      <Row className="" justify={"end"}>
        {filter.get("sort_by") === "date" && (
          <Col
            md={5}
            className={`flex justify-end items-center gap-5 flex-wrap`}
          >
            <div className=" border border-Green p-2 rounded-xl relative flex items-center justify-center">
              <span className="absolute -top-3 bg-white px-2 left-3">Date</span>
              <input
                type="date"
                value={filter.get("sort_value")}
                onChange={(e) => {
                  setFilter({
                    sort_value: e.target.value,
                    sort_by: filter.get("sort_by"),
                    page: filter.get("page"),
                    state: filter.get("state"),
                  });
                }}
              />
            </div>
          </Col>
        )}
        <Col md={2} className={"flex justify-end"}>
          <div className="border border-Pink flex space-x-2 p-2 rounded-xl max-sm:w-full">
            <select
              value={filter.get("sort_by")}
              onChange={(e) =>
                setFilter({
                  sort_by: e.target.value,
                  sort_value: filter.get("sort_value"),
                })
              }
              className="outline-none text-center"
            >
              <option value="">الكل</option>
              {/* <option value="id">id</option> */}
              <option className="text-black" value="date">
                يوم محدد
              </option>
              {/* <option className="text-black" value="dayToDay">
                (من-الى) تاريخ
              </option> */}
            </select>
          </div>
        </Col>
      </Row>
      <div className="w-1/2 h-[360px] max-sm:w-full mx-auto flex flex-col mt-5 bgimg border-2 border-Green rounded-2xl p-10 ">
        <h1 className="text-2xl font-semibold mb-10">مجموع المدخلات</h1>
        <div className="border border-Green h-full rounded-2xl bg-white flex items-center justify-center">
          <h1>{data?.data.data.income} دينار</h1>
        </div>
      </div>
    </div>
  );
};

export default Income;
