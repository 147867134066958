import React from "react";
import ButtonRed from "./ButtonRed";
import Buttons from "./Buttons";

const Back = ({ name, onClick, error }) => {
  return (
    <div className="fixed bottom-10 left-10 flex flex-col gap-2 items-end">
      <div className="text-red-600">{error}</div>
      <Buttons
        onClick={onClick}
        name={name}
        className={`bg-Purple py-2 px-5  w-fit ${name ? "" : "hidden"}`}
      />
      <ButtonRed
        className="py-2 px-5 w-fit"
        name="رجوع"
        onClick={() => window.history.go(-1)}
      />
    </div>
  );
};

export default Back;
