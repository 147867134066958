import React, { useEffect, useState } from "react";
import { Loading, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";

const AddUser = () => {
  const [coP, setCoP] = useState();
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
    setError,
  } = usePOST({});
  const { data } = useFETCH(`admin/users/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    if (formData?.password || coP) {
      if (coP === formData?.password) {
        handleSubmit(id === "add" ? "admin/users" : `admin/users/${id}`);
      } else {
        setError("خطأ في تأكيد كلمة السر");
      }
    } else {
      handleSubmit(id === "add" ? "admin/users" : `admin/users/${id}`);
    }
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        username: dataOld?.username,
        email: dataOld?.email,
        phone_number: dataOld?.phone_number,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Purple rounded-2xl p-10 ">
            <Title title="Add User" className="mb-14 mt-5" />
            <input
              type="text"
              name="username"
              value={formData?.username}
              onChange={handleChangeInput}
              placeholder="اسم المستخدم"
              autocomplete="off"
              className="border py-4 rounded-xl mb-4 "
            />
            <input
              type="text"
              name="email"
              value={formData?.email}
              onChange={handleChangeInput}
              placeholder="الايميل "
              autocomplete="off"
              className="border py-4 rounded-xl mb-4 "
            />
            <input
              type="text"
              name="phone_number"
              value={formData?.phone_number}
              onChange={handleChangeInput}
              placeholder="رقم الهاتف"
              autocomplete="off"
              className="border py-4 rounded-xl mb-4 "
            />
            <input
              type="password"
              name="password"
              value={formData?.password}
              onChange={handleChangeInput}
              placeholder="كلمة السر"
              autocomplete="off" 
              className="border py-4 rounded-xl mb-4 "
            />
            <input
              type="password"
              name="password"
              onChange={(e) => setCoP(e.target.value)}
              placeholder="تـأكيد كلمة السر"
              autocomplete="off" 
              className="border py-4 rounded-xl mb-4 "
            />
            {loading ? <Loading /> : ""}
            <div className="text-red-600">{error}</div>
            <input
              type="submit"
              onClick={handleSubmitMain}
              placeholder="Send"
              className="border py-4 rounded-xl mb-4 text-center text-white font-bold text-xl cursor-pointer bg-Green"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddUser;
