import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { AddImage, Back, Input, Loading, TextArea } from "../../components";
import { useEffect, useState } from "react";

const AddProductOne = () => {
  const [deleteImgs, setDeleteImgs] = useState(false);
  const { id } = useParams();
  const {
    handleChangeInput,
    handleChangeArrayImages,
    handleCheckedArray,
    handleSubmit,
    viewImages,
    setViewImages,
    images,
    setImages,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(`admin/products/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "admin/products" : `admin/products/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataOld?.name,
        description: dataOld?.description,
        price: dataOld?.price,
        qut: dataOld?.qut,
        has_limit: dataOld?.has_limit,
      });
    id === "add" &&
      setFormData({
        ...formData,
        has_limit: formData?.has_limit || 0,
      });
  }, [dataOld]);

  return (
    <div>
      <Row className="p-4" justify="center">
        <Col md={7}>
          <div className="border-2 border-Purple rounded-2xl px-3 py-10 space-y-5">
            <div className="flex justify-center gap-5 w-full"></div>
            <Input
              name="name"
              value={formData?.name}
              onChange={handleChangeInput}
              title="اسم المنتج"
            />
            <TextArea
              name="description"
              value={formData?.description}
              onChange={handleChangeInput}
              title="وصف"
            />
            <Input
              name="price"
              value={formData?.price}
              onChange={handleChangeInput}
              title="السعر"
              type="number"
            />
            <Input
              name="qut"
              value={formData?.qut}
              onChange={handleChangeInput}
              title="الكمية المتوفرة"
              type="number"
            />
            <div className="flex gap-3 items-center ml-4">
              <input
                type="checkbox"
                name="has_limit"
                checked={formData?.has_limit}
                onChange={handleChangeInput}
                className="w-5 h-5"
              />
              <h1>لديه حد من الكمية </h1>
            </div>
            {id === "add" && (
              <div className="flex gap-3 items-center ml-4">
                <input
                  type="checkbox"
                  name="notifiable"
                  onChange={handleChangeInput}
                  className="w-5 h-5"
                />
                <h1>هل تريد ان يتم ارسال اشعار للتطبيق عند اضافة المنتج</h1>
              </div>
            )}

            <AddImage
              type="IMAGES"
              name="images[]"
              onChange={handleChangeArrayImages}
            />
            <Row className="py-4">
              {viewImages["images[]"] &&
                viewImages["images[]"]?.map((e, i) => (
                  <Col md={3} xs={4} sm={6} lg={24}>
                    <div className="relative">
                      <span
                        onClick={() => {
                          let total = [...viewImages["images[]"]];
                          let total2 = [...images["images[]"]];
                          total.splice(i, 1);
                          total2.splice(i, 1);
                          setViewImages({
                            ...viewImages["images[]"],
                            "images[]": total,
                          });
                          setImages({
                            ...images["images[]"],
                            "images[]": total2,
                          });
                        }}
                        className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                      >
                        X
                      </span>
                      <img src={e} alt="" className="w-full rounded-2xl" />
                    </div>
                  </Col>
                ))}
            </Row>
            {id !== "add" && (
              <div>
                <div
                  onClick={() => setDeleteImgs(!deleteImgs)}
                  className="cursor-pointer px-5 py-2 border border-Green w-fit rounded-lg text-lg  font-semibold text-white bg-Green my-2 ml-auto "
                >
                  حذف الصور
                </div>
                <Row>
                  {dataOld?.images.map((e) => (
                    <Col key={e.id} md={4} sm={6} className="relative">
                      {deleteImgs && (
                        <input
                          type="checkbox"
                          value={e.id}
                          name="images_to_delete[]"
                          onChange={handleCheckedArray}
                          className="absolute -top-2 left-1 h-5 w-5"
                        />
                      )}
                      <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                        <img
                          src={fileUrl + e.image}
                          alt=""
                          className="w-full h-full object-contain rounded-2xl"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}

            <div className="text-red-600">{error}</div>
          </div>
        </Col>
      </Row>

      {loading ? <Loading /> : ""}

      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default AddProductOne;
