import {
  AddProductOne,
  AddUser,
  ChangePassword,
  EditHome,
  Home,
  Login,
  OrdersUser,
  Products,
  Users,
  ProductDetails,
  Income,
  Order,
  Orders,
  WarningUsers,
  AddWarningUsers,
  EditOrder,
} from "./pages/index";
import { Navbar, SideBar } from "./components/index";
import { Route, Routes } from "react-router-dom";
import Logo from "./images/logo.jpg";
import { RequireAuth, useFETCH } from "./APIs/useMyAPI";
import { Container } from "./Grid-system";

const App = () => {
  const { data } = useFETCH("check/auth");
  return (  
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route element={<RequireAuth />}>
                <Route
                  path=""
                  element={
                    <h1 className="grid place-content-center h-[60vh]">
                      <img src={Logo} alt="" className="w-[250px]" />
                    </h1>
                  }
                />
                <Route
                  path="*"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      404 | no page found
                    </h1>
                  }
                />
                <Route
                  path="403"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      403 | FORBIDDEN
                    </h1>
                  }
                />
                <Route
                  path="500"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      500 | Internal Server Error
                    </h1>
                  }
                />
                <Route path="home" element={<Home />} />
                <Route path="home/:id" element={<EditHome />} />
                <Route path="users">
                  <Route index element={<Users />} />
                  <Route path=":id" element={<AddUser />} />
                  <Route path="Orders-user/:id" element={<OrdersUser />} />
                </Route>
                <Route path="income" element={<Income />} />
                <Route path="products">
                  <Route index element={<Products />} />
                  <Route path="show/:id" element={<ProductDetails />} />
                  <Route path=":id" element={<AddProductOne />} />
                </Route>
                <Route path="Orders">
                  <Route index element={<Orders />} />
                  <Route path=":id" element={<Order />} />
                  <Route path="edit/:id" element={<EditOrder />} />
                </Route>
                <Route path="warning-users">
                  <Route index element={<WarningUsers />} />
                  <Route path=":id" element={<AddWarningUsers />} />
                </Route>

                <Route path="Change-Password" element={<ChangePassword />} />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
