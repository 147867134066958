function Input({ title, onChange, name, type, value }) {
  return (
    <div>
      <input
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className="border border-Green m-1 py-3 rounded-xl"
      />
    </div>
  );
}
export default Input;
