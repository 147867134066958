import React from "react";
import { Col, Row } from "../../Grid-system";
import { CardBox, Loading, Product, Title } from "../../components";
import { fileUrl, useFETCH } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
const Order = () => {
  const { id } = useParams();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/orders/${id}/items`,
    "admin/orders/items"
  );
  const { data: dataUeser } = useFETCH(`admin/orders/${id}`);

  return (
    <section className="py-4">
      <Title title="طلب" />
      {isLoading ? <Loading /> : ""}
      <Row
        justify="start"
        className="border  border-boldGreen rounded-2xl p-5 mb-5"
      >
        <h2 className="text-xl font-bold text-Green mb-3 text-center">
          معلومات الطلب
        </h2>

        <Col md={3} className="flex gap-2 mb-2 justify-start">
          <div className="font-semibold text-boldGreen">ID :</div>
          <div>{dataUeser?.data.data.id}</div>
        </Col>
        <Col md={3} className="flex gap-2 mb-2 justify-start">
          <div className="font-semibold text-boldGreen"> اسم المستخدم :</div>
          <div>{dataUeser?.data.data.user_name}</div>
        </Col>
        <Col md={3} className="flex gap-2 mb-2 justify-start">
          <div className="font-semibold text-boldGreen"> حالة الدفع :</div>
          <div className="text-green-600 font-semibold  ">
            {dataUeser?.data.data.state}
          </div>
        </Col>
        <Col md={3} className="flex gap-2 mb-2 justify-start">
          <div className="font-semibold text-boldGreen"> السعر الكلي :</div>
          <div>{dataUeser?.data.data.price} دينار</div>
        </Col>
        <Col md={3} className="flex gap-2 mb-2 justify-start">
          <div className="font-semibold text-boldGreen"> التاريخ : </div>
          <div>{dataUeser?.data.data.created_at.substring(0, 10)}</div>
        </Col>
        <Col md={3} className="flex gap-2 mb-2 justify-start">
          <div className="font-semibold text-boldGreen"> الوقت : </div>
          <div>{dataUeser?.data.data.created_at.substring(10)}</div>
        </Col>
      </Row>
      <Row
        justify="center"
        className="border border-boldGreen rounded-2xl p-5 mb-5"
      >
        {data?.data.data.map((e) => (
          <Col md={4} xs={6} className="">
            <CardBox
              deleteClick={() => deleteItem(e)}
              edit={`/Orders/edit/${e.id}`}
            >
              <Product
                name={e.product_name}
                price={e.price}
                title={e.descriptoin}
                remaining={e.count}
                gr={true}
                view="true"
              >
                <Swiper className="" slidesPerView={1} spaceBetween={20}>
                  {e.images.map((im) => (
                    <SwiperSlide key={e.id} className="">
                      <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                        <img
                          src={fileUrl + im.image}
                          alt=""
                          className="w-full h-full object-contain rounded-2xl"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Product>
            </CardBox>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default Order;
