import React from "react";

const Buttons = ({ name, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${className} h-full px-4 py-2 rounded-xl hover:bg-opacity-70 text-center font-semibold bg-Green text-white cursor-pointer`}
    >
      {name}
    </div>
  );
};

export default Buttons;
