import { fileUrl, useFETCH } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { ButtonRed, CardBox, Loading, Title } from "../../components";

const ImageSlider = () => {
  const { data, isLoading, deleteItem } = useFETCH(`admin/slider/images`);
  console.log(data);
  return (
    <>
      <Title title="صور" />
      <Row>
        {isLoading ? (
          <Loading />
        ) : (
          data?.data.data.map((e) => (
            <Col md={4} xs={6}>
              <CardBox deleteClick={() => deleteItem(e)} edit={`/home/${e.id}`}>
                <div className="w-full h-[250px] bg-slate-100 rounded-md p-2">
                  <img
                    src={fileUrl + e.image}
                    alt=""
                    className="w-full h-full object-contain rounded-2xl"
                  />
                </div>
              </CardBox>
            </Col>
          ))
        )}
      </Row>
      <ButtonRed
        name="اضافة صور"
        className="w-fit ml-auto px-5 py-2"
        link="/home/add"
      />
    </>
  );
};

export default ImageSlider;
