import { Col, Row } from "../../Grid-system";
import {
  AcceptBtn,
  DeleteTable,
  FilterOrderStataus,
  Loading,
  Pagination,
  Title,
} from "../../components";
import accept from "../../images/Icon material-done.png";

import searchs from "../../images/Icon feather-search.png";

import { Link } from "react-router-dom";
import { BiShow } from "react-icons/bi";
import { useFETCH, useFilter, usePOST } from "../../APIs/useMyAPI";
import { useState } from "react";

const Orders = () => {
  const { filter, setFilter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/orders${
      filter.get("page") ||
      filter.get("sort_by") ||
      filter.get("sort_value") ||
      filter.get("state")
        ? "?"
        : ""
    }${filter.get("state") ? "&state=" + filter.get("state") : ""}
    ${filter.get("sort_by") ? "&sort_by=" + filter.get("sort_by") : ""}
    ${filter.get("sort_value") ? "&sort_value=" + filter.get("sort_value") : ""}
    ${filter.get("page") ? "&page=" + filter.get("page") : ""}`,
    "admin/orders"
  );
  const { loading, handleSubmit, error, setError } = usePOST({ state: "2" });
  const handleSubmitState = (e) => {
    handleSubmit(`admin/orders/${e}`, true);
  };
  const {
    loading: load2,
    handleSubmit: handleSubmit2,
    error: error2,
    setError: setError2,
  } = usePOST({
    state: "1",
  });
  const handleSubmitState2 = (e) => {
    handleSubmit2(`admin/orders/${e}`, true);
  };
  return (
    <div>
      <Col md={2}>
        <Title className="mb-5" title="طلبات" />
      </Col>
      <Row className="" justify={"end"}>
        {filter.get("sort_by") === "date" && (
          <Col
            md={5}
            className={`flex justify-end items-center gap-5 flex-wrap`}
          >
            <div className=" border border-Green p-2 rounded-xl relative flex items-center justify-center">
              <span className="absolute -top-3 bg-white px-2 left-3">Date</span>
              <input
                type="date"
                value={filter.get("sort_value")}
                onChange={(e) => {
                  setFilter({
                    sort_value: e.target.value,
                    sort_by: filter.get("sort_by"),
                    page: filter.get("page"),
                    state: filter.get("state"),
                  });
                }}
              />
            </div>
          </Col>
        )}
        {filter.get("sort_by") === "id" && (
          <Col
            md={5}
            className={`flex justify-end items-center gap-5 flex-wrap`}
          >
            <div className="border border-Pink flex space-x-2 p-2 rounded-xl">
              <span>
                <img src={searchs} alt="" />
              </span>
              <input
                type="search"
                value={filter.get("sort_value")}
                onChange={(e) =>
                  setFilter({
                    sort_value: e.target.value,
                    sort_by: filter.get("sort_by"),
                    page: filter.get("page"),
                    state: filter.get("state"),
                  })
                }
                placeholder="Search"
                className=""
              />
            </div>
          </Col>
        )}

        <Col md={2} className={"flex justify-end"}>
          <div className="border border-Pink flex space-x-2 p-2 rounded-xl max-sm:w-full">
            <select
              value={filter.get("sort_by")}
              onChange={(e) =>
                setFilter({
                  sort_by: e.target.value,
                  sort_value: filter.get("sort_value"),
                  state: filter.get("state"),
                  page: filter.get("page"),
                })
              }
              className="outline-none text-center"
            >
              <option value="">الكل</option>
              <option value="id">id</option>
              <option className="text-black" value="date">
                يوم محدد
              </option>
            </select>
          </div>
        </Col>
      </Row>
      <FilterOrderStataus />
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          <Col>
            <div className="overflow-x-scroll ">
              <table className="w-full max-sm:w-[460px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-Pink">
                <thead className="bg-Green text-white h-[70px]">
                  <tr>
                    <td className="">اسم المستخدم</td>
                    <td className="max-md:hidden">ID</td>
                    <td>حالة الطلب</td>
                    <td className="max-md:hidden">التاريخ</td>
                    <td className="max-md:hidden">الوقت</td>
                    <td>السعر</td>
                    <td className="w-[200px]">حدث</td>
                  </tr>
                </thead>
                <tbody className="">
                  {loading || load2 ? <Loading /> : ""}
                  {isLoading ? (
                    <Loading />
                  ) : (
                    data?.data.data.data.map((e) => (
                      <tr className="border border-Pink ">
                        <td className="">{e.user_name}</td>
                        <td className="max-md:hidden">{e.id}</td>
                        <td
                          className={`${
                            e.state === "غير مدفوع "
                              ? "text-red-600"
                              : "text-green-600"
                          }  font-semibold py-7`}
                        >
                          {e.state}
                        </td>
                        <td className="max-md:hidden">
                          {e.created_at.substring(0, 10)}
                        </td>
                        <td className="max-md:hidden">
                          {e.created_at.substring(10)}
                        </td>
                        <td className="text-green-600 font-semibold  ">
                          {e.price} دينار
                        </td>
                        <td className="text-red-600 font-semibold ">
                          <div className="flex h-full  justify-center gap-3 items-center">
                            {e.state === "غير مدفوع " ? (
                              <div className="flex gap-1 items-center">
                                <DeleteTable
                                  deleteClick={() => deleteItem(e)}
                                />
                                <AcceptBtn
                                  ID={e.id}
                                  onClickt={() => setError("")}
                                  error={error}
                                  onClick={() => handleSubmitState(e.id)}
                                />
                              </div>
                            ) : (
                              <div className="flex gap-1 items-center juc">
                                <CoBtn
                                  ID={e.id}
                                  onClickt={() => setError2("")}
                                  error={error2}
                                  onClick={() => handleSubmitState2(e.id)}
                                />
                              </div>
                            )}
                            <Link to={`/Orders/${e.id}`}>
                              <BiShow
                                className="mx-auto cursor-pointer"
                                size={25}
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Pagination>
    </div>
  );
};

export default Orders;
const CoBtn = ({ onClick, ID, error, onClickt }) => {
  const [sure, setSure] = useState(false);

  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => {
              setSure(false);
              onClickt();
            }}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 rounded-3xl -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل أنت متأكد من تغيير حالة هذا الطلب{ID} ؟
              </p>
              <div className="text-black">{sure ? error : ""}</div>
              <div className="flex items-end m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-green-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={onClick}
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => {
                    setSure(false);
                    onClickt();
                  }}
                  className="px-7 py-3 bg-red-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        onClick={() => setSure(true)}
        className="w-[130px]  mx-auto mb-1 py-2 px-2 border-2 rounded-xl font-semibold border-red-600 text-red-600 flex gap-2 items-center bg-white cursor-pointer"
      >
        <p className=""> غير مدفوع</p>
        <span>X</span>
      </div>
    </>
  );
};
