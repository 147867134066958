import { BiShow } from "react-icons/bi";
import { Col, Row } from "../../Grid-system";
import {
  AcceptBtn,
  DeleteTable,
  Loading,
  Pagination,
  Title,
} from "../../components";

import { Link, useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useState } from "react";

const OrdersUser = () => {
  const { id } = useParams();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users/${id}/orders`,
    "admin/orders"
  );

  const { loading, handleSubmit, error, setError } = usePOST({ state: "2" });
  const handleSubmitState = (e) => {
    handleSubmit(`admin/orders/${e}`, true);
  };
  const {
    loading: load2,
    handleSubmit: handleSubmit2,
    error: error2,
    setError: setError2,
  } = usePOST({
    state: "1",
  });
  const handleSubmitState2 = (e) => {
    handleSubmit2(`admin/orders/${e}`, true);
  };
  return (
    <div>
      <Col md={2} className="mb-4">
        <Title title="Order-User" />
      </Col>

      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          <Col>
            <div className="overflow-x-scroll ">
              <table className="w-full max-sm:w-[460px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-Pink">
                <thead className="bg-Green text-white h-[70px]">
                  <tr>
                    <td className="">user name</td>
                    <td className="max-md:hidden">ID</td>
                    <td>Order status</td>
                    <td className="max-md:hidden">Date</td>
                    <td className="max-md:hidden">Time</td>
                    <td>Price</td>
                    <td className="w-[200px]">Action</td>
                  </tr>
                </thead>
                <tbody className="">
                  {loading || load2 ? <Loading /> : ""}
                  {isLoading ? (
                    <Loading />
                  ) : (
                    data?.data.data.data.map((e) => (
                      <tr className="border border-Pink ">
                        <td className="">{e.user_name}</td>
                        <td className="max-md:hidden">{e.id}</td>
                        <td className="text-green-600 font-semibold py-7">
                          {e.state}
                        </td>
                        <td className="max-md:hidden">
                          {e.created_at.substring(0, 10)}
                        </td>
                        <td className="max-md:hidden">
                          {e.created_at.substring(10)}
                        </td>
                        <td className="text-green-600 font-semibold  ">
                          {e.price} دينار
                        </td>
                        <td className="text-red-600 font-semibold ">
                          <div className="flex h-full  justify-center gap-3 items-center">
                            {e.state === "غير مدفوع " ? (
                              <div className="flex gap-1 items-center">
                                <DeleteTable
                                  deleteClick={() => deleteItem(e)}
                                />
                                <AcceptBtn
                                  ID={e.id}
                                  onClickt={() => setError("")}
                                  error={error}
                                  onClick={() => handleSubmitState(e.id)}
                                />
                              </div>
                            ) : (
                              <div className="flex gap-1 items-center juc">
                                <CoBtn
                                  ID={e.id}
                                  onClickt={() => setError2("")}
                                  error={error2}
                                  onClick={() => handleSubmitState2(e.id)}
                                />
                              </div>
                            )}
                            <Link to={`/Orders/${e.id}`}>
                              <BiShow
                                className="mx-auto cursor-pointer"
                                size={25}
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Pagination>
    </div>
  );
};

export default OrdersUser;

const CoBtn = ({ onClick, ID, error, onClickt }) => {
  const [sure, setSure] = useState(false);

  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => {
              setSure(false);
              onClickt();
            }}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 rounded-3xl -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل أنت متأكد من تغيير حالة هذا الطلب{ID} ؟
              </p>
              <div className="text-black">{sure ? error : ""}</div>
              <div className="flex items-end m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-green-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={onClick}
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => {
                    setSure(false);
                    onClickt();
                  }}
                  className="px-7 py-3 bg-red-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        onClick={() => setSure(true)}
        className="w-[130px]  mx-auto mb-1 py-2 px-2 border-2 rounded-xl font-semibold border-red-600 text-red-600 flex gap-2 items-center bg-white cursor-pointer"
      >
        <p className=""> غير مدفوع</p>
        <span>X</span>
      </div>
    </>
  );
};
