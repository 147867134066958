import React from "react";
import {
  Add,
  ButtonRed,
  CardBox,
  Loading,
  Pagination,
  Title,
} from "../../components";
import { Col, Row } from "../../Grid-system";
import search from "../../images/Icon feather-search.png";
import { Link } from "react-router-dom";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Users = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users${filter.get("page") ? "?page=" + filter.get("page") : ""}`,
    "admin/users"
  );

  return (
    <div>
      <Row className="" justify={"between"}>
        <Col md={4}>  
          <Title title="مستخدمين" />
        </Col>
        {/* <Col md={6} className={"flex justify-end"}>
          <div className="flex flex-wrap gap-3">
            <div className="border border-Green flex space-x-2 p-2 rounded-xl">
              <span>
                <img src={search} alt="" />
              </span>
              <input type="search" placeholder="Search" className="" />
            </div>
          </div>
        </Col> */}
      </Row>
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row className={"pt-5"}>
          {data?.data.data.data.map((e) => (
            <Col key={e.id} lg={4} md={6} className="mb-2">
              <CardBox
                deleteClick={() => deleteItem(e)}
                className={"relative"}
                edit={`/users/${e.id}`}
              >
                <div className="flex justify-start gap-2 mb-2">
                  <div className="font-semibold text-Green">اسم المستخدم : </div>
                  <div>{e.username}</div>
                </div>
                <div className="flex justify-start gap-2 mb-2">
                  <div className="font-semibold text-Green">الأيميل :</div>
                  <div>{e.email}</div>
                </div>
                <div className="flex justify-start gap-2 mb-2">
                  <div className="font-semibold text-Green">الهاتف :</div>
                  <div>{e.phone_number}</div>
                </div>
                <Row className="justify-center pt-3">
                  <Col col={6}>
                    <ButtonRed
                      className="py-2"
                      link={`/users/Orders-user/${e.id}`}
                      name="طلبات"
                    />
                  </Col>
                </Row>
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>

      <Link to={`/users/add`}>
        <Add />
      </Link>
    </div>
  );
};

export default Users;
