import { Link } from "react-router-dom";
import { Col, Row } from "../../Grid-system";
import {
  Add,
  CardBox,
  Loading,
  Pagination,
  Product,
  Title,
} from "../../components";
import { fileUrl, useFETCH, useFilter } from "../../APIs/useMyAPI";

const Products = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/products${filter.get("page") ? "?page=" + filter.get("page") : ""}`,
    "admin/products"
  );

  return (
    <div>
      <Row className="" justify={"between"}>
        <Col md={4}>
          <Title title="منتجات" />
        </Col>
        <Pagination
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
        >
          <Row>
            {isLoading ? (
              <Loading />
            ) : (
              data?.data.data.data.map((e) => (
                <Col lg={4} md={4} xs={6}>
                  <CardBox
                    deleteClick={() => deleteItem(e)}
                    key={e.id}
                    className={
                      "bg-white overflow-hidden mx-1 relative my-2 rounded-3xl transition-all hover:-translate-y-2 shadow-md hover:shadow-xl"
                    }
                    edit={`/products/${e.id}`}
                  >
                    <Product
                      src={fileUrl + e.images[0].image}
                      name={e.name}
                      price={e.price}
                      title={e.description}
                      remaining={e.qut}
                      link={`/products/show/${e.id}`}
                    />
                  </CardBox>
                </Col>
              ))
            )}
          </Row>
        </Pagination>
      </Row>
      <Link to={`/products/add`}>
        <Add />
      </Link>
    </div>
  );
};

export default Products;
