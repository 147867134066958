import { useState } from "react";
import ButtonRed from "../Buttons/ButtonRed";
import { Link } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdMode } from "react-icons/md";
const CardBox = ({
  deleted,
  children,
  deleteClick,
  edit,
  className,
  showEdit,
}) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full h-full top-0 left-0 popup z-40 flex justify-center items-center`}
      >
        <div className="bg-white z-50 rounded-3xl w-[500px] max-w-[500px] min-h-[200px] ">
          <p className="font-semibold text-3xl text-center py-7">
            Are you sure to delete the item ?
          </p>
          <div className="flex items-end m-5">
            <ButtonRed
              onClick={deleteClick}
              name="Yes"
              className="py-3 px-14 border border-Green"
            />
            <button
              onClick={() => setSure(false)}
              className=" border px-10 border-Pbg-Green text-Pbg-Green bg-white font-semibold  py-3 rounded-xl ml-5"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div
        className={`border-2 border-Green rounded-2xl p-3 bgimg w-full h-full ${className}`}
      >
        <div className="flex justify-end gap-1 mb-1">
          {deleted ? (
            ""
          ) : (
            <div className="w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center">
              <RiDeleteBin5Line size={30} color="#206B35" onClick={() => setSure(true)} />
            </div>
          )}
          <div
            className={`${
              showEdit ? "hidden" : ""
            } w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
          >
            <Link to={edit || ""}>
              <MdMode size={30} color="#206B35" />
            </Link>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default CardBox;
