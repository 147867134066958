import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.jpg";

function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  return (
    <>
      <div
        className={`${openMenu ? "w-[360px]" : " w-[0px]"} ${
          openMenu
            ? " max-sm:translate-x-0 max-sm:w-[80%] "
            : " max-sm:-translate-x-full"
        } sidebar h-[100vh] bg-Green max-sm:h-[100%] sticky max-sm:fixed right-0 top-0 transition-all overflow-y-scroll  py-10 z-40`}
      >
        <div
          className={`max-sm:block hidden text-end mx-4 text-xl font-semibold cursor-pointer`}
          onClick={changeMenu}
        >
          X
        </div>
        <div className="px-3">
          <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
            <li className="">
              <img
                src={logo}
                alt=""
                className="w-[150px] h-[150px] bg-white rounded-full mx-auto"
              />
            </li>
            <li className=" ">
              <NavLink
                to={"/Home"}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
              >
                صفحة الرئيسية
              </NavLink>
            </li>
            <li className=" ">
              <NavLink
                to={"/products"}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
              >
                منتجات
              </NavLink>
            </li>
            <li className=" ">
              <NavLink
                to={"/Users"}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
              >
                مستخدمين
              </NavLink>
            </li>
            <li className=" ">
              <NavLink
                to={"/Orders"}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
              >
                طلبات
              </NavLink>
            </li>
            <li className=" ">
              <NavLink
                to={"/warning-users"}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
              >
                قسم التفتيش
              </NavLink>
            </li>
            <li className=" ">
              <NavLink
                to={"/income"}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
              >
                مدخلات
              </NavLink>
            </li>
            <li className="pb-10">
              <NavLink
                to={"/Change-Password"}
                className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
              >
                تغيير كلمة السر
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div
        onClick={() => changeMenu(false)}
        className={`${
          openMenu ? "" : "hidden"
        } sm:hidden w-[20%] h-[100vh] bg-black  bg-opacity-30  fixed left-0 top-0 transition-all    z-40 `}
      ></div>
    </>
  );
}

export default SideBar;
