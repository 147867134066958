import React, { useEffect } from "react";
import { Col, Row } from "../../Grid-system";
import { Back, Input, Loading } from "../../components";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";

const AddWarningUsers = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(`admin/warning-users/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "admin/warning-users" : `admin/warning-users/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataOld?.name,
        number: dataOld?.number,
      });
  }, [dataOld]);
  return (
    <div>
      <Row className="p-4" justify="center">
        <Col md={7}>
          <div className="border-2 border-Purple rounded-2xl px-3 py-10 space-y-5">
            <div className="flex justify-center gap-5 w-full"></div>
            <Input
              name="name"
              value={formData?.name}
              onChange={handleChangeInput}
              title="الاسم"
            />
            <Input
              name="number"
              value={formData?.number}
              onChange={handleChangeInput}
              title="الرقم"
            />

            <div className="text-red-600">{error}</div>
          </div>
        </Col>
      </Row>
      {loading ? <Loading /> : ""}
      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default AddWarningUsers;
