import { Link } from "react-router-dom";

function ProductCard(props) {
  return (
    <>
      <Link to={props.link || ""}>
        <div>{props.children}</div>
        {props.src && (
          <div className="w-full h-[200px] bg-slate-100 rounded-md p-2 mx-auto ">
            <img
              src={props.src}
              alt=""
              className="w-full h-full object-contain   max-sm:h-[150px] rounded-xl"
            />
          </div>
        )}

        <div className="flex justify-between items-center mx-2">
          <p className="text-Green font-semibold text-xl">
            {props.price} دينار
          </p>
          <h1 className="text-xl font-bold text-center">{props.name}</h1>
        </div>
        <p className="text-end">{props.title}</p>
        <p className="text-end font-bold text-Green text-xl">
          {props.gr ? "الكمية المطلوبة " : "الكمية المتوفرة "} :
          <span className="text-black text-lg font-normal">
            {props.remaining}
          </span>
        </p>
      </Link>
    </>
  );
}
export default ProductCard;
