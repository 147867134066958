import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { AddImage, Back, ButtonRed, Loading } from "../../components";

const EditHome = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    viewFile,
    setViewFile,
    error,
    loading,
    setFormData,
    formData,
    handleSubmit,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "admin/slider/images" : `admin/slider/images/${id}`
    );
  };
  const { data } = useFETCH(`admin/slider/images/${id}`);

  return (
    <div>
      <Row justify={"center"}>
        {loading ? <Loading /> : ""}
        <Col md={12} className="">
          <Row justify="center">
            <Col>
              <AddImage
                name="image"
                oldImage={
                  data?.data.data.image && fileUrl + data?.data.data.image
                }
                clickDeleteImage={() => {
                  setFormData("");
                  setViewFile("");
                }}
                
                newImage={viewFile}
                onChange={handleChangeInput}
                title="أضف صورة"
                clickDelete={() => {
                  setViewFile("");
                  setFormData({ ...formData, images: "" });
                }}
              />
            </Col>

            <Col col={6}>
              <div className="text-red-600">{error}</div>
              <ButtonRed
                name="Add"
                onClick={handleSubmitMain}
                className="h-full px-4 py-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default EditHome;
