import { useParams } from "react-router-dom";
import { fileUrl, useFETCH } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { ButtonRed, Loading } from "../../components";

function ProductDetails() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/products/${id}`);

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Row className="mb-8">
        {data?.data.data.images?.map((e) => (
          <Col lg={3} md={4} xs={6}>
            <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-full object-contain rounded-2xl"
              />
            </div>
          </Col>
        ))}
      </Row>
      <Row>
        <Col
          md={8}
          className="mx-auto text-center text-xl border-t-4 border-Green pt-2 "
        >
          <h1 className="font-bold text-2xl"> {data?.data.data.name}</h1>
          <p>{data?.data.data.description}</p>
          <div className="flex justify-between items-center flex-wrap my-3">
            <p className="text-end font-bold text-Green text-xl">
              الكمية المتوفرة :
              <span className="text-black text-lg font-normal">
                {data?.data.data.qut}
              </span>
            </p>
            <p className="text-end font-bold text-Green text-xl">
              سعر المنتج :
              <span className="text-black text-lg font-normal">
                {data?.data.data.price}
              </span>
            </p>
          </div>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-10">
        <ButtonRed link={`/products/${id}`} name="تعديل" className="px-7 py-2" />
      </div>
    </div>
  );
}
export default ProductDetails;
