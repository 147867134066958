function TextArea({ title, onChange, name, type, value }) {
  return (
    <div>
      <textarea
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className="border border-Green outline-none m-1 py-3 rounded-xl block w-full px-3 h-[150px] "
      />
    </div>
  );
}
export default TextArea;
