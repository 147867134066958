import React, { useEffect } from "react";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { Back, Input, Loading } from "../../components";
import { useParams } from "react-router-dom";

const EditOrder = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    // setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  //   const { data } = useFETCH(`orders/items/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/orders/items/${id}`);
  };
  //   console.log(data);
  //   let dataOld = data?.data.data;
  //   useEffect(() => {
  //     setFormData({
  //       count: dataOld?.count,
  //     });
  //   }, [dataOld]);
  //   console.log(dataOld);
  return (
    <div>
      <Row className="p-4" justify="center">
        <Col md={7}>
          <div className="border-2 border-Purple rounded-2xl px-3 py-10 space-y-5">
            <div className="flex justify-center gap-5 w-full"></div>
            <Input
              name="count"
              value={formData?.count}
              onChange={handleChangeInput}
              title=" تعديل الكمية المطلوبة"
            />
          </div>
          {id === "add" && (
            <div className="flex gap-3 items-center ml-4">
              <input
                type="checkbox"
                name="notifiable"
                onChange={handleChangeInput}
                className="w-5 h-5"
              />
              <h1>هل تريد ان يتم ارسال اشعار للتطبيق عند اضافة المنتج</h1>
            </div>
          )}
          <div className="text-red-600">{error}</div>
        </Col>
      </Row>

      {loading ? <Loading /> : ""}

      <Back name="حفظ" onClick={handleSubmitMain} />
    </div>
  );
};

export default EditOrder;
