import React from "react";

const Add = () => {
  return (
    <>
      <div
        className={`flex z-10 justify-center fixed bottom-10 left-5 font-bold text-5xl text-white cursor-pointer w-14 h-14 rounded-full bg-Green`}
      >
        +
      </div>
    </>
  );
};

export default Add;
