import React from "react";
import { Col, Row } from "../../Grid-system";
import { useFilter } from "../../APIs/useMyAPI";

const FilterOrderStataus = () => {
  const { filter, setFilter } = useFilter();
  return (
    <Row>
      <Col md={4}>
        <select
          name=""
          id=""
          value={filter.get("state")}
          onChange={(e) =>
            setFilter({
              state: e.target.value,
              sort_value: filter.get("sort_value"),
              sort_by: filter.get("sort_by"),
              page: filter.get("page"),
            })
          }
          className="w-full py-2 text-center border-2 outline-none border-Pink rounded-xl"
        >
          <option value="">الكل</option>
          <option value="2">تم الدفع</option>
          <option value="1">لم يتم الدفع</option>
        </select>
      </Col>
    </Row>
  );
};

export default FilterOrderStataus;
