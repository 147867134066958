import { useState } from "react";
import accept from "../../images/Icon material-done.png";

const AcceptBtn = ({ onClick, ID, error, onClickt }) => {
  const [sure, setSure] = useState(false);

  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => {
              setSure(false);
              onClickt();
            }}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 rounded-3xl -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white   z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل أنت متأكد من تغيير حالة هذا الطلب{ID} ؟
              </p>
              <div className="text-black">{error}</div>
              <div className="flex items-end m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-green-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={onClick}
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => {
                    setSure(false);
                    onClickt();
                  }}
                  className="px-7 py-3 bg-red-600 my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        onClick={() => setSure(true)}
        className="w-[100px]  mx-auto mb-1 py-2 px-2 border-2 rounded-xl font-semibold border-green-600 text-green-600 flex gap-2 items-center bg-white cursor-pointer"
      >
        <span className="">تم الدفع</span>
        <span>
          <img src={accept} alt="" width={15} />
        </span>
      </div>
    </>
  );
};

export default AcceptBtn;
